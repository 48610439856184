import './bootstrap';
import './vue.js';

$(document).ready(function() {
    if (typeof loader != "undefined") {
        loader.hide();
    }

    if ($("#ie-browser-warning").length) {
        UIkit.modal("#ie-browser-warning", {}).show();
    }

    flatpickr('[data-toggle="date-picker"]', {
        allowInput: true,
        weekNumbers: true,
        dateFormat: "d/m/Y",
    });
    flatpickr('[data-toggle="datetime-picker"]', {
        allowInput: true,
        weekNumbers: true,
        enableTime: true,
        enableSeconds: false,
        dateFormat: "d/m/Y H:i",
    });
    flatpickr('[data-toggle="time-picker"]', {
        allowInput: true,
        enableTime: true,
        noCalendar: true,
        enableSeconds: false,
        dateFormat: "H:i",
    });
});

var loader = {
    show: function () {
        $.busyLoadFull("show");
    },
    hide: function () {
        $.busyLoadFull("hide");
    },
};

var refresh_src = function (e) {
    e.src = e.src + "?" + Date.now();
};

var get_area_district = function (e) {
    var selector = $("#district_id_selector");

    clear_selector("#ward_id_selector");

    get_select_value(e, selector, "/area/district/select/");
};

var get_area_ward = function (e) {
    var selector = $("#ward_id_selector");

    get_select_value(e, selector, "/area/ward/select/");
};

var get_content_category = function (e) {
    var selector = $("#cate_id_selector");

    get_select_value(e, selector, "/content/category/select/");
};

var get_select_value = function (e, selector, url) {
    if (!selector.length) {
        return false;
    }

    var parent_id = e.options[e.selectedIndex].value;
    var parent_name = e.name;

    if (!parent_id) {
        clear_selector(selector);
        return false;
    }

    data = {};
    data[parent_name] = parent_id;

    $.ajax({
        url: base_url + url,
        dataType: "json",
        data: data,
        beforeSend: function (jqXHR, settings) {
        clear_selector(selector);
        loader.show();
    },
    complete: function (jqXHR, textStatus) {
        loader.hide();
    },
    success: function (data, textStatus, jqXHR) {
        if (data.length) {
        var items = [];

        for (var i = 0, len = data.length; i < len; i++) {
            items.push(
                '<option value="' +
                data[i].id +
                '">' +
                data[i].title +
                " (" +
                data[i].id +
                ")" +
                "</option>"
            );
        }

        selector.append(items.join(""));
        }
    },
    error: function (jqXHR, textStatus, errorThrown) {},
    });
};

var clear_selector = function (selector) {
    if (false === selector instanceof jQuery) {
        selector = $(selector);
    }

    selector.children("[value!='']").remove();
};
