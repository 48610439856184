<script setup>
import {useI18n} from "vue-i18n";
import {useCartStore} from '@/stores/cartStore';

const {t, n} = useI18n();

const props = defineProps({
    edit: {
        type: Boolean,
        default: true
    },
});

const cartStore = useCartStore();
</script>

<template>
    <div class="uk-text-small">
        <table v-if="(edit ? cartStore.notEmpty : cartStore.selectedItemCount) > 0"
            class="uk-table uk-table-divider uk-table-responsive uk-table-small uk-table-justify uk-table-middle uk-margin">
            <thead>
            <tr class="uk-text-small">
                <td class="uk-width-auto">{{ $t('product') }}</td>
                <td class="uk-text-right">{{ $t('unit_price') }}</td>
                <td :class="{'uk-text-center': edit, 'uk-text-right': !edit}">{{ $t('quantity') }}</td>
                <td class="uk-text-right">{{ $t('subtotal') }}</td>
                <td v-if="edit" class="width-80"></td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in  (edit ? cartStore.items : cartStore.selectedItems)" :key="index">
                <td>
                    <div uk-grid class="uk-grid-small uk-child-width-auto uk-flex-middle">
                        <div v-if="edit" class="width-40">
                            <input type="checkbox" class="uk-checkbox" name="checkbox"
                                v-model="item.options.selected" @change="cartStore.update(item)">
                        </div>
                        <div>
                            <a :href="`/${item.options.page_uri}`" class="uk-link-reset">
                                <img :src="item.options.avatar"
                                    class="uk-border-rounded uk-preserve-width width-80 border-primary">
                            </a>
                        </div>
                        <div>
                            <ul class="uk-list uk-list-collapse">
                                <li>
                                    <a :href="`/${item.options.page_uri}`" class="uk-link-reset" v-text="item.name" />
                                </li>
                                <li v-if="item.options.model_title" v-text="item.options.model_title"
                                    class="uk-text-primary" />
                            </ul>
                        </div>
                    </div>
                </td>
                <td class="uk-text-right">
                    <label class="uk-hidden@m">{{ $t('unit_price') }}:</label>
                    {{ $n(item.price, 'currency') }}
                </td>
                <td v-if="edit" class="uk-text-center@m uk-text-right">
                    <label class="uk-hidden@m uk-margin-small-right">{{ $t('quantity') }}</label>
                    <div class="uk-inline">
                        <a class="uk-form-icon"
                            @click="cartStore.decreaseQty(item)">
                            <i class="uk-text-primary bi bi-dash"></i>
                        </a>
                        <input id="quantity" class="uk-input uk-form-width-small uk-text-center uk-border-rounded"
                            type="number"
                            min="1" :value="item.qty" style="padding-right: 40px !important;">
                        <a class="uk-form-icon uk-form-icon-flip"
                            @click="cartStore.increaseQty(item)">
                            <i class="uk-text-primary bi bi-plus"></i>
                        </a>
                    </div>
                </td>
                <td v-else class="uk-text-right">
                    <label class="uk-hidden@m">{{ $t('quantity') }}:</label>
                    {{ $n(item.qty, 'integer') }}
                </td>
                <td class="uk-text-primary uk-text-right">
                    <label class="uk-hidden@m">{{ $t('subtotal') }}:</label>
                    {{ $n(item.qty * item.price, 'currency') }}
                </td>
                <td v-if="edit" class="uk-text-center@m uk-text-right">
                    <a @click="cartStore.remove(item)" class="uk-link-reset" :title="$t('remove')">
                        <i class="bi bi-trash"></i>
                        <span class="uk-margin-small-left uk-hidden@m">{{ $t('remove') }}</span>
                    </a>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="uk-text-bold">
                    <div uk-grid class="uk-grid-small uk-child-width-auto uk-flex-middle">
                        <div v-if="edit" class="width-40">
                            <input id="cart-toggle" type="checkbox" class="uk-checkbox" name="checkbox"
                                :checked="cartStore.selected" @click="cartStore.toggleAll()">
                        </div>
                        <div>
                            {{ $t('total') }} ({{ $n(cartStore.selectedItemCount, 'integer') }}
                            {{ $t('products').toLowerCase() }})
                        </div>
                    </div>
                </td>
                <td class="uk-text-primary uk-text-right uk-text-bold">
                    {{ $n(cartStore.total, 'currency') }}
                </td>
                <td v-if="edit" class="uk-text-center@m uk-text-right">
                    <a @click="cartStore.clear()" class="uk-link-reset" :title="$t('clear')">
                        <i class="bi bi-trash"></i>
                        <span class="uk-margin-small-left uk-hidden@m">{{ $t('clear') }}</span>
                    </a>
                </td>
            </tr>
            </tbody>
        </table>
        <div v-if="cartStore.selectedItemCount > 0">
            <div v-if="edit" class="uk-text-center">
                <a class="uk-button uk-button-primary uk-border-rounded" href="/order/check">
                    {{ $t('check_out') }}
                </a>
            </div>
        </div>
        <div v-else class="uk-text-center">
            <span v-if="edit">
                {{ $t('no_selected_product') }}
            </span>
            <span v-else>
                {{ $t('no_product') }}
            </span>
        </div>
    </div>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>

<script>

export default {};
</script>
