import { createApp } from "vue";
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia';

import {configure} from "vee-validate";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import Product from "@/components/Product.vue";
import CartButton from "@/components/CartButton.vue";
import CartContent from "@/components/CartContent.vue";
import Order from "@/components/Order.vue";

const app = createApp({});
app.component("cart-button", CartButton);
app.component("cart-content", CartContent);
app.component("product", Product);
app.component("order", Order);

const i18n = createI18n({
    locale: 'vi-VN',
    legacy: false,
    globalInjection: true,
    messages: {
        'vi-VN': {
            'unit_price': 'Đơn giá',
            'price': 'Giá',
            'price_orig': 'Giá gốc',
            'quantity': 'Số lượng',
            'qty': 'SL',
            'stock': 'Tồn kho',
            'out_of_stock': 'Hết hàng',
            'cart': 'Giỏ hàng',
            'order': 'Đơn hàng',
            'order_id': 'Mã đơn hàng',
            'order_summary': 'Tổng kết đơn',
            'name': 'Tên',
            'recipient_name': 'Tên người nhận',
            'tel': 'Số điện thoại',
            'address': 'Địa chỉ',
            'note': 'Ghi chú',
            'city': 'Tỉnh/thành',
            'district': 'Quận/huyện',
            'ward': 'Phường/xã',
            'view_cart': 'Xem giỏ hàng',
            'continue_shopping': 'Tiếp tục mua hàng',
            'add_to_cart': 'Thêm vào giỏ',
            'purchase_now': 'Mua ngay',
            'action': 'Thao tác',
            'select': 'Chọn',
            'remove': 'Xoá',
            'clear': 'Xoá hết',
            'product': 'Sản phẩm',
            'product_id': 'Mã sản phẩm',
            'products': 'Sản phẩm',
            'products_in_cart': 'Sản phẩm trong giỏ',
            'product_list': 'DS sản phẩm',
            'no_product': 'Chưa có sản phẩm nào!',
            'no_selected_product': 'Chưa chọn sản phẩm nào!',
            'recent_added_products': 'Sản phẩm mới thêm',
            'model': 'Mẫu',
            'select_a_model': 'Vui lòng chọn một mẫu để xem giá và số lượng tồn kho!',
            'subtotal': 'Thành tiền',
            'total': 'Tổng cộng',
            'check_out': 'Mua hàng',
            'place_order': 'Đặt hàng',
            'go_back': 'Quay lại',
            'go_home': 'Về trang chủ',
            'product_model_not_selected': 'Vui lòng chọn một sản phẩm!',
            'invalid_quantity': 'Số lượng không hợp lệ!',
            'product_added': 'Sản phẩm đã được thêm vào giỏ!',
            'shipping_address': 'Địa chỉ nhận hàng',
            'detail_address': 'Địa chỉ cụ thể',
            'delivery_method': 'Phuơng thức Giao hàng',
            'payment_method': 'Phương thức Thanh toán',
            'cod': 'Thanh toán khi nhận hàng (COD)',
            'product_subtotal': 'Tiền mua sản phẩm',
            'shipping_subtotal': 'Phí vận chuyển',
            'discount_total': 'Số tiền giảm giá',
            'payment_total': 'Tổng tiền thanh toán',
            'payment_guide': 'Hướng dẫn thanh toán',
            'payment': 'Thanh toán',
            'order_placed': 'Đặt hàng thành công',
            'order_placed_nice': 'Cảm ơn quý khách đã đặt hàng! Chúng tôi sẽ liên hệ với số điện thoại Quý khách cung cấp để xác nhận đơn.',
            'keep_order_id': 'Vui lòng giữ Mã đơn hàng để tra cứu khi cần.',
            'keep_order_id2': 'Vui lòng giữ Mã đơn hàng để tra cứu khi cần, hoặc Quý khách có thể <a class="uk-text-primary" href="/sign-up">đăng ký tài khoản</a> để thuận tiện hơn cho những lần mua sau',
            'validation': {
                'required': '{field} không được bỏ trống',
                'phone': '{field} không phải số điện thoại hợp lệ',
                'phone_vn': '{field} không phải số điện thoại VN hợp lệ',
            }
        },
        'en-US': {
            'price': 'Price',
            'price_orig': 'Orig. Price',
            'quantity': 'Quantity',
            'qty': 'Qty',
            'stock': 'Stock',
            'out_of_stock': 'Out of Stock',
            'cart': 'Cart',
            'order': 'Order',
            'order_id': 'Order ID',
            'order_summary': 'Order Summary',
            'name': 'Name',
            'recipient_name': 'Recipient name',
            'tel': 'Phone',
            'address': 'Address',
            'note': 'Note',
            'city': 'City',
            'district': 'District',
            'ward': 'Ward',
            'view_cart': 'View cart',
            'continue_shopping': 'Continue shopping',
            'add_to_cart': 'Add to cart',
            'purchase_now': 'Purchase now',
            'action': 'Action',
            'select': 'Select',
            'remove': 'Remove',
            'clear': 'Clear',
            'product': 'Product',
            'product_id': 'Product ID',
            'products': 'Product(s)',
            'products_in_cart': 'Product(s) in cart',
            'product_list': 'Product list',
            'no_product': 'No product yet!',
            'no_selected_product': 'No selected product yet!',
            'recent_added_products': 'Recent added product(s)',
            'model': 'Model',
            'select_a_model': 'Please select a model to view price and stock!',
            'subtotal': 'Subtotal',
            'total': 'Total',
            'check_out': 'Check out',
            'place_order': 'Place order',
            'go_back': 'Go back',
            'go_home': 'Go home',
            'product_model_not_selected': 'Product model is not selected!',
            'invalid_quantity': 'Invalid quantity!',
            'product_added': 'Product added to cart!',
            'shipping_address': 'Shipping address',
            'detail_address': 'Detail address',
            'delivery_method': 'Delivery method',
            'payment_method': 'Payment method',
            'cod': 'Cash on Delivery (COD)',
            'product_subtotal': 'Product subtotal',
            'shipping_subtotal': 'Shipping subtotal',
            'discount_total': 'Total discount',
            'payment_total': 'Total payment',
            'payment_guide': 'Payment guide',
            'payment': 'Payment',
            'order_placed': 'Order placed successfully',
            'order_placed_nice': 'Thanks for ordering! We will contact with your provided phone number to confirm.',
            'keep_order_id': 'Please keep your Order ID for reference if needed.',
            'keep_order_id2': 'Please keep your Order ID for reference if needed, or you can <a class="uk-text-primary" href="/sign-up">register an account</a> for easier future purchases.',
            'validation': {
                'required': '{field} is required',
                'phone': '{field} is not valid phone number',
                'phone_vn': '{field} is not valid VN phone number',
            }
        }
    },
    numberFormats: {
        'en-US': {
            currency: {
                style: 'currency', currency: 'USD', notation: 'standard'
            },
            integer: {
                style: 'decimal', maximumFractionDigits: 0
            },
            decimal: {
                style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
            },
            percent: {
                style: 'percent', useGrouping: false
            }
        },
        'vi-VN': {
            currency: {
                style: 'currency', currency: 'VND', useGrouping: true, currencyDisplay: 'symbol'
            },
            integer: {
                style: 'decimal', maximumFractionDigits: 0
            },
            decimal: {
                style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
            },
            percent: {
                style: 'percent', useGrouping: false
            }
        },
    }
});
app.use(i18n);

app.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 1,
    newestOnTop: true,
    timeout: 3000, // Thời gian hiển thị mặc định (ms)
    position: 'top-right', // Vị trí mặc định
    hideProgressBar: false, // Hiển thị thanh tiến trình
    closeOnClick: true, // Đóng khi click
});

const pinia = createPinia();
app.use(pinia);

app.mount("#app");

configure({
    validateOnBlur: true, // Xác thực khi mất focus
    validateOnInput: true, // Không xác thực khi nhập
    validateOnChange: true, // Không xác thực khi thay đổi
    validateOnModelUpdate: false, // Không xác thực khi cập nhật mô hình
});
