<script setup>
import {computed, onMounted} from 'vue';
import {useCartStore} from '@/stores/cartStore';

const cartStore = useCartStore();
cartStore.getContent();

const lastItems = computed(() => cartStore.items.slice(-3).reverse());
</script>

<template>
    <div class="uk-inline">
        <a class="uk-button uk-button-default uk-border-rounded" :title="$t('cart')">
            <i class="bi bi-cart"></i>
            <span v-if="cartStore.notEmpty" class="uk-badge uk-margin-small-left">{{ cartStore.itemCount }}</span>
        </a>
        <div class="uk-width-large uk-border-rounded" uk-dropdown="mode: hover; pos: bottom-right;">
            <h5 v-if="cartStore.notEmpty" class="uk-heading-divider">
                {{ $t('recent_added_products') }}
            </h5>
            <table v-if="cartStore.notEmpty"
                class="uk-table uk-table-small uk-table-divider uk-table-justify uk-margin-small">
                <tbody>
                <tr v-for="(item, index) in lastItems" :key="index">
                    <td class="uk-table-link uk-table-shrink">
                        <a :href="`/${item.options.page_uri}`" class="uk-link-reset">
                            <img :src="item.options.avatar"
                                class="uk-border-rounded uk-preserve-width width-80 border-primary">
                        </a>
                    </td>
                    <td class="uk-table-expand">
                        <ul class="uk-list uk-list-collapse">
                            <li>
                                <a :href="`/${item.options.page_uri}`" class="uk-link-reset">
                                    {{ item.name }} ({{ $t('qty') }}: {{ $n(item.qty, 'integer') }})
                                </a>
                            </li>
                            <li v-if="item.options.model_title" v-text="item.options.model_title"
                                class="uk-text-primary" />
                        </ul>
                    </td>
                    <td class="uk-text-primary uk-text-right">
                        {{ $n(item.price, 'currency') }}
                    </td>
                </tr>
                </tbody>
            </table>
            <div uk-grid class="uk-grid-small uk-flex-middle">
                <div class="uk-width-expand">
                    <div v-if="cartStore.notEmpty">
                        {{ $n(cartStore.itemCount, 'integer') }} {{ $t('products_in_cart').toLowerCase() }}
                    </div>
                    <div v-else class="uk-text-center">{{ $t('no_product') }}</div>
                </div>
                <div v-if="cartStore.notEmpty" class="uk-width-auto">
                    <div uk-grid class="uk-grid-small">
                        <div>
                            <a class="uk-button uk-button-default uk-border-rounded" @click="cartStore.clear()"
                                :title="$t('clear')">
                                <i class="bi bi-trash" style="font-size: 20px;"></i>
                            </a>
                        </div>
                        <div>
                            <a class="uk-button uk-button-primary uk-border-rounded" href="/cart">{{ $t('view_cart')
                                }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
