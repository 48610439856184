<script setup>
import {useOrderStore} from "@/stores/orderStore";

const orderStore = useOrderStore();
</script>

<template>
    <ul class="uk-list uk-list-divider">
        <li>
            <div uk-grid class="uk-grid-small">
                <div class="uk-width-auto">
                    <input type="radio" class="uk-radio" v-model="orderStore.paymentMethod"/>
                </div>
                <div class="uk-width-expand">
                    <div v-text="$t('cod')"/>
                </div>
            </div>
        </li>
    </ul>
</template>
