<script setup>
import {useI18n} from "vue-i18n";
import {useOrderStore} from "@/stores/orderStore";

const {t, n} = useI18n();

const orderStore = useOrderStore();
</script>

<template>
    <div>
        <div class="uk-padding uk-border-rounded border-primary border-dashed uk-text-center">
            <h5 class="uk-heading-divider" v-text="$t('payment')" />
        </div>
    </div>
</template>
