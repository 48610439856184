<script setup>
import {useI18n} from "vue-i18n";
import {useOrderStore} from "@/stores/orderStore";

const {t, n} = useI18n();

const orderStore = useOrderStore();
</script>

<template>
    <div>
        <div class="uk-padding uk-border-rounded border-primary border-dashed uk-text-center">
            <div class="uk-margin-small">
                <i class="uk-text-success bi bi-check-circle"
                    style="font-size: 60px;">
                </i>
            </div>

            <h5 class="uk-heading-divider" v-text="$t('order_placed')" />

            <div v-text="$t('order_placed_nice')" class="uk-margin-small"></div>

            <div v-if="orderStore.order != null" class="uk-margin-small">
                <label v-text="$t('order_id')"></label>:
                <span class="uk-text-primary" v-text="orderStore.order.mask_id"></span>
            </div>

            <div v-html="$t('keep_order_id')"></div>
        </div>
    </div>
</template>
