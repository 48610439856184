<template>
    <h3>
        <span v-if="hasVideo" class="uk-badge uk-background-danger uk-margin-small-right">{{ $t('video') }}</span>
        <span class="uk-text-bold">{{ product.title }}</span>
    </h3>

    <!-- if product has models -->
    <div v-if="models.length > 0">
        <!-- if NO product model is selected -->
        <div v-if="notSelectedModel" class="uk-margin">
            <div uk-grid class="uk-grid-small uk-child-width-auto uk-text-bold">
                <div>
                    <label>{{ $t('price') }}:</label>
                </div>
                <div>
                    <span v-if="lowestPrice !== null && !isNaN(lowestPrice)" class="uk-text-primary">
                        {{ $n(lowestPrice, 'currency') }}
                    </span>
                </div>
                <template v-if="lowestPrice !== null && highestPrice !== null && lowestPrice !== highestPrice">
                    <div>-</div>
                    <div>
                        <span v-if="!isNaN(highestPrice)" class="uk-text-primary">
                            {{ $n(highestPrice, 'currency') }}
                        </span>
                    </div>
                </template>
            </div>
            <div class="uk-margin uk-text-warning" v-text="$t('select_a_model')"></div>
        </div>

        <!-- if a product model is selected -->
        <div v-else class="uk-margin">
            <div uk-grid class="uk-grid-small uk-child-width-auto uk-text-bold">
                <div>
                    <label>{{ $t('price') }}:</label>
                </div>
                <div class="uk-text-primary" v-if="selectedModel.price !== null && !isNaN(selectedModel.price)">
                    {{ $n(selectedModel.price, 'currency') }}
                </div>
                <div
                    v-if="selectedModel.price_orig !== null && selectedModel.price !== null && selectedModel.price_orig !== selectedModel.price && !isNaN(selectedModel.price_orig)"
                    class="price-orig uk-text-small">
                    {{ $n(selectedModel.price_orig, 'currency') }}
                </div>
            </div>
        </div>

        <!-- show all models -->
        <div uk-grid class="uk-grid-small">
            <div class="uk-flex uk-flex-middle width-80">
                <label>{{ $t('model') }} ({{ models.length }})</label>
            </div>
            <div class="uk-width-expand@s uk-height-max-medium uk-overflow-auto">
                <div uk-grid uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 100"
                    class="uk-grid-small">
                    <div v-for="(model, index) in models" :key="index" class="uk-with-auto">
                        <div v-if="model.avatar" class="uk-inline">
                            <div class="uk-form-icon">
                                <img :src="model.avatar" alt="" width="30" height="30" />
                            </div>
                            <button
                                class="uk-button uk-border-rounded padding-icon-left"
                                @click="selectModel(model)"
                                :class="{'uk-button-primary': model.selected && available, 'uk-button-default': !model.selected || (model.selected && !available)}"
                            >
                                {{ model.title }}
                            </button>
                        </div>
                        <button v-else
                            class="uk-button uk-border-rounded"
                            @click="selectModel(model)"
                            :class="{'uk-button-primary': model.selected && available, 'uk-button-default': !model.selected || (model.selected && !available)}"
                        >
                            {{ model.title }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- if product has NO models -->
    <div v-else>
        <div uk-grid class="uk-grid-small uk-child-width-auto uk-margin-small">
            <div class="uk-text-bold">
                <label>{{ $t('price') }}:</label>
            </div>
            <div class="uk-text-primary uk-text-bold" v-if="product.price !== null && !isNaN(product.price)">
                {{ $n(product.price, 'currency') }}
            </div>
            <div
                v-if="product.price_orig !== null && product.price !== null && product.price_orig !== product.price && !isNaN(product.price_orig)"
                class="price-orig uk-text-small">
                {{ $n(product.price_orig, 'currency') }}
            </div>
        </div>
    </div>

    <div class="uk-margin">
        <template v-if="!hasModels || (hasModels && !notSelectedModel)">
            <template v-if="available">
                <div uk-grid class="uk-grid-small uk-flex uk-flex-middle">
                    <div class="width-80">
                        <label for="qty">{{ $t('quantity') }}</label>
                    </div>
                    <div class="uk-with-auto@s">
                        <div uk-grid class="uk-grid-small uk-flex uk-flex-middle">
                            <div class="uk-width-auto">
                                <div class="uk-inline">
                                    <a class="uk-form-icon uk-link-reset" @click="decreaseQty()">
                                        <i class="uk-form-icon uk-text-primary bi bi-dash"></i>
                                    </a>
                                    <input id="qty"
                                        class="uk-input uk-form-width-small uk-text-center uk-border-rounded"
                                        type="number"
                                        min="1" :value="qty" style="padding-right: 40px !important;">
                                    <a class="uk-form-icon uk-form-icon-flip uk-link-reset" @click="increaseQty()">
                                        <i class="uk-form-icon uk-text-primary bi bi-plus"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="uk-width-auto">
                                <label>{{ $t('stock') }}:</label>&nbsp;{{ stock }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-grid-small">
                    <div v-if="cartStore.loading">
                        <Spinner />
                    </div>
                    <div v-else>
                        <button
                            class="uk-button uk-button-primary uk-border-rounded uk-width-1-1 uk-width-medium@s padding-icon"
                            @click="addToCart()">
                            <i class="text-white bi bi-cart-plus uk-margin-small-right"></i>
                            {{ $t('add_to_cart') }}
                        </button>
                    </div>
                </div>
            </template>
            <template v-else>
                <button class="uk-button uk-button-default uk-border-rounded uk-width-1-1 uk-width-medium@s" disabled>
                    {{ $t('out_of_stock') }}
                </button>
            </template>
        </template>
    </div>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>

<script setup>
import {useI18n} from 'vue-i18n';
import {useCartStore} from '@/stores/cartStore';
import {computed, ref} from "vue";
import {showToast} from '@/helpers/toastHelper';
import Spinner from "@/components/Spinner.vue";

const {t, n} = useI18n();
const cartStore = useCartStore();

const props = defineProps({
    product: {
        type: Object,
        default: () => {},
        required: true,
    },
    video: {
        type: Object,
        default: () => null,
    },
    images: {
        type: Array,
        default: () => [],
        required: false,
    },
});

const emits = defineEmits(['select', 'hover'])

const selectedModel = ref(null);
const lowestPrice = ref(null);
const highestPrice = ref(null);
const qty = ref(1);
const models = ref(props.product.models);

const stock = computed(() => {
    if (models.value.length > 0) {
        return selectedModel.value ? selectedModel.value.stock : null;
    }
    else {
        return props.product.stock;
    }
})

const available = computed(() => stock.value !== null && stock.value > 0)
const hasVideo = computed(() => props.video !== null && props.video !== undefined)
const hasModels = computed(() => models.value.length > 0)
const notSelectedModel = computed(() => null === selectedModel.value)

const increaseQty = () => qty.value++;
const decreaseQty = () => {
    if (qty.value > 1) {
        qty.value--;
    }
}

const addToCart = () => {
    if (invalidPurchased()) {
        return;
    }
    cartStore.add(props.product, selectedModel.value, qty.value);
}

const purchaseNow = () => {
    if (invalidPurchased()) {
        return;
    }
    cartStore.purchase(props.product, selectedModel.value, qty.value);
}

const invalidPurchased = () => {
    if (hasModels.value && notSelectedModel.value) {
        showToast.error(t("product_model_not_selected"));
        return true;
    }

    if (isNaN(qty.value) || (qty.value < 1)) {
        showToast.error(t('invalid_quantity'));
        return true;
    }

    return false;
}

const selectModel = (model) => {
    if (model.selected) {
        model.selected = false;
        selectedModel.value = null;
        emits('select')
    }
    else {
        model.selected = true;

        if (selectedModel.value) {
            selectedModel.value.selected = false;
        }

        selectedModel.value = model;
        emits('select', model);
    }
}

const showModels = () => {
    if (models.value.length > 0) {
        models.value.forEach(function (model) {
            if (lowestPrice.value == null) {
                lowestPrice.value = model.price;
            }
            else if (model.price < lowestPrice.value) {
                lowestPrice.value = model.price;
            }

            if (highestPrice.value == null) {
                highestPrice.value = model.price;
            }
            else if (model.price > highestPrice.value) {
                highestPrice.value = model.price;
            }
        });
    }
}

showModels();
</script>
