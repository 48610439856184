<template>
    <div class="uk-margin-small">
        <Splide ref="main" :options="mainOptions" :extensions="extensions" aria-label="">
            <SplideSlide v-if="hasVideo()" :data-splide-html-video="video.video_url">
                <img class="uk-border-rounded border-primary" :src="video.thumbnail_url" alt="" />
            </SplideSlide>
            <template v-for="(image, index) in medias" :key="index">
                <SplideSlide v-if="image.type === 'image'">
                    <a v-on:click="showLightbox(index)">
                        <img class="uk-border-rounded border-primary" :src="image.href" alt="">
                    </a>
                </SplideSlide>
            </template>
        </Splide>
    </div>

    <div>
        <Splide ref="thumbs" :options="thumbsOptions" aria-label="">
            <SplideSlide v-if="hasVideo()">
                <img class="uk-border-rounded" :src="video.thumbnail_url" alt="" />
            </SplideSlide>
            <template v-for="(image, index) in medias" :key="index">
                <SplideSlide v-if="image.type === 'image'">
                    <img class="uk-border-rounded" :src="image.href" alt="">
                </SplideSlide>
            </template>
        </Splide>
    </div>
</template>

<style scoped>
#product-thumbnail-slider .splide__slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#product-thumbnail-slider .splide__slide {
    opacity: 0.6;
}

#product-thumbnail-slider .splide__slide.is-active {
    opacity: 1;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
    border: 2px solid #666;
    border-radius: 7px;
}
</style>

<script>
import {defineComponent, onMounted, ref, watch} from 'vue';

import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

import {Splide, SplideSlide} from '@splidejs/vue-splide';
import {Video} from '@splidejs/splide-extension-video';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
import '@splidejs/vue-splide/css';

export default defineComponent({
    setup() {
        const mainOptions = {
            type: 'fade',
            rewind: true,
            pagination: false,
            arrows: false,
            lazyLoad: 'nearby',
            video: {
                loop: false,
                mute: true,
                autoplay: true,
            }
        };

        const thumbsOptions = {
            fixedWidth: 100,
            fixedHeight: 100,
            gap: '0.5rem',
            rewind: true,
            pagination: false,
            arrows: true,
            isNavigation: true,
            updateOnMove: true,
            breakpoints: {
                600: {
                    fixedWidth: 60,
                    fixedHeight: 60,
                },
            },
        };

        const main = ref();
        const thumbs = ref();

        onMounted(() => {
            const thumbsSplide = thumbs.value?.splide;

            if (thumbsSplide) {
                main.value?.sync(thumbsSplide);
            }
        });

        return {
            main,
            thumbs,
            mainOptions,
            thumbsOptions,
            extensions: {Video},
        };
    },
    components: {
        Splide,
        SplideSlide,
    },
    props: {
        product: Object,
        video: Object,
        images: Array,
        selectedModel: Object
    },
    watch: {
        selectedModel(newModel, oldModel) {
            if (newModel && newModel.avatar) {
                this.medias.forEach((media, index) => {
                    if (media.type === 'image' && media.href === newModel.avatar) {
                        this.main.go(index);
                    }
                })
            }
        }
    },
    data() {
        return {
            medias: [],
            lightbox: null,
        };
    },
    methods: {
        hasVideo() {
            return this.video !== null && this.video !== undefined;
        },
        hasImages() {
            return this.images.length > 0;
        },
        hasMedias() {
            return this.hasVideo() && this.hasImages();
        },
        hasModels() {
            return this.product && this.product.models && this.product.models.length > 0;
        },
        initLightbox() {
            if (this.hasVideo()) {
                this.medias.push({
                    'href': this.video.video_url,
                    'image': this.video.thumbnail_url,
                    'type': 'video',
                });
            }

            if (this.hasImages()) {
                this.images.forEach((image) => {
                    this.medias.push({
                        'href': image,
                        'type': 'image',
                    });
                });
            }

            if (this.hasModels()) {
                this.product.models.forEach((model) => {
                    this.medias.push({
                        'href': model.avatar,
                        'type': 'image',
                        'name': model.title,
                    });
                });
            }

            this.lightbox = GLightbox({
                elements: this.medias,
                touchNavigation: true,
                loop: true,
                autoplayVideo: true,
                openEffect: 'fade',
                closeEffect: 'fade',
                slideEffect: 'fade',
            });
        },
        showLightbox(index) {
            this.lightbox.openAt(index);
        },
    },
    mounted() {
        this.initLightbox();
    },
});
</script>
