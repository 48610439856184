<script setup>
import {useCartStore} from "@/stores/cartStore";
import {useOrderStore} from "@/stores/orderStore";
import {ref} from "vue";

import CartContent from "@/components/CartContent.vue";
import OrderAddress from "@/components/OrderAddress.vue";
import OrderPaymentMethod from "@/components/OrderPaymentMethod.vue";
import OrderSummary from "@/components/OrderSummary.vue";
import OrderCompleted from "@/components/OrderCompleted.vue";
import OrderPayment from "@/components/OrderPayment.vue";
import Spinner from "@/components/Spinner.vue";

const props = defineProps({
    shippingPolicy: {type: String, deffault: ''}
})

const cartStore = useCartStore();
const orderStore = useOrderStore();

const formAddressRef = ref(null);

const placeOrder = () => {
    if (formAddressRef.value) {
        formAddressRef.value.submitForm();
    }
}
</script>

<template>
    <div class="uk-text-small">
        <div class="heading-divider">
            <div>
                <h4>{{ $t('order') }}</h4>
            </div>
        </div>
        <div class="uk-padding uk-padding-remove-horizontal">
            <div v-if="orderStore.completed">
                <div uk-grid uk-height-match="target: > div > div > div"
                    class="uk-grid uk-grid-divider uk-child-width-1-2@s uk-flex-center uk-margin">
                    <div>
                        <order-completed />
                    </div>

                    <div v-if="orderStore.order && orderStore.order.paymentMethod">
                        <order-payment />
                    </div>
                </div>
                <div class="uk-text-center">
                    <a href="/" class="uk-button uk-button-primary uk-border-rounded">
                        {{ $t('go_home') }}
                    </a>
                </div>
            </div>

            <div v-else>
                <div class="uk-margin">
                    <cart-content :edit="false" />
                </div>

                <div v-if="cartStore.selectedItemCount > 0" class="uk-margin">
                    <div uk-grid class="uk-grid-small uk-grid-match">
                        <div class="uk-width-1-2@s uk-width-1-4@m">
                            <div class="uk-padding-small uk-background-muted uk-border-rounded">
                                <h5 class="uk-heading-divider" v-text="$t('order_summary')" />
                                <order-summary :shipping-policy="shippingPolicy"/>
                            </div>
                        </div>
                        <div class="uk-width-1-2@s uk-width-1-4@m">
                            <div class="uk-padding-small uk-background-muted uk-border-rounded">
                                <h5 class="uk-heading-divider" v-text="$t('payment_method')" />
                                <order-payment-method />
                            </div>
                        </div>
                        <div class="uk-width-1-2@m">
                            <div class="uk-padding-small uk-background-muted uk-border-rounded">
                                <h5 class="uk-heading-divider" v-text="$t('shipping_address')" />
                                <order-address ref="formAddressRef" />
                            </div>
                        </div>
                    </div>
                </div>
                <div uk-grid class="uk-grid-small">
                    <div class="uk-width-expand">
                        <a href="/cart" class="uk-button uk-button-default uk-border-rounded" :title="$t('back')"
                            v-text="$t('go_back')"></a>
                    </div>
                    <div v-if="cartStore.selectedItemCount > 0" class="uk-width-auto">
                        <Spinner v-if="orderStore.loading" />
                        <button
                            v-else
                            v-text="$t('place_order')"
                            @click="placeOrder()"
                            class="uk-button uk-button-primary uk-border-rounded">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
