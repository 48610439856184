<script setup>
import {ref} from "vue";
import {useI18n} from "vue-i18n";
import {ErrorMessage, Field, Form} from "vee-validate";
import * as yup from "yup";
import "yup-phone-lite";

import {useOrderStore} from "@/stores/orderStore";

const {t, n} = useI18n();

const schema = yup.object({
    name: yup.string()
        .required(t('validation.required', {field: t('name')})),

    tel: yup.string().label(t('tel'))
        .phone('VN', t('validation.phone_vn', {field: t('tel')}))
        .required(t('validation.required', {field: t('tel')})),

    cityId: yup.string()
        .required(t('validation.required', {field: t('city')})),

    districtId: yup.string()
        .required(t('validation.required', {field: t('district')})),

    wardId: yup.string()
        .required(t('validation.required', {field: t('ward')})),

    detail: yup.string()
        .required(t('validation.required', {field: t('detail_address')})),
});

const orderStore = useOrderStore();
const address = orderStore.address;
const note = orderStore.note;

const formRef = ref(null);
const buttonSubmit = ref(null);
const inputName = ref(null);

const submitForm = () => {
    // formRef.value.submit(); // not work
    buttonSubmit.value.click();

    if (!formRef.value.validated) {
        inputName.value.focus();
    }
};

const onSubmit = () => {
    orderStore.place();
};

orderStore.getCities();

defineExpose({
    submitForm,
});
</script>

<template>
    <Form ref="formRef" @submit="onSubmit" :validation-schema="schema" class="uk-form-stacked">
        <button ref="buttonSubmit" type="submit" class="uk-hidden">Submit</button>
        <div uk-grid class="uk-grid-small uk-child-width-1-2 uk-margin">
            <div>
                <label class="uk-form-label">{{ $t('recipient_name') }}<span
                    class="red-dot uk-text-danger uk-margin-small-left"></span></label>
                <div class="uk-form-controls">
                    <Field name="name" v-model="address.name" v-slot="{ field, meta }">
                        <input v-bind="field" type="text" ref="inputName"
                               :class="{'uk-input uk-border-rounded': true, 'uk-form-danger': meta.touched && !meta.valid}"/>
                    </Field>
                </div>
                <ErrorMessage name="name" as="div" class="uk-text-danger uk-text-small uk-margin-small-top"/>
            </div>
            <div>
                <label class="uk-form-label">{{ $t('tel') }}<span
                    class="red-dot uk-text-danger uk-margin-small-left"></span></label>
                <div class="uk-form-controls">
                    <Field name="tel" v-model="address.tel" v-slot="{ field, meta }">
                        <input v-bind="field" type="tel"
                               :class="{'uk-input uk-border-rounded': true, 'uk-form-danger': meta.touched && !meta.valid}"/>
                    </Field>
                </div>
                <ErrorMessage name="tel" as="div" class="uk-text-danger uk-text-small uk-margin-small-top"/>
            </div>
        </div>
        <div uk-grid class="uk-grid-small uk-child-width-1-3 uk-margin">
            <div>
                <label class="uk-form-label">{{ $t('city') }}<span
                    class="red-dot uk-text-danger uk-margin-small-left"></span></label>
                <div class="uk-form-controls">
                    <Field name="cityId" v-model="address.cityId" v-slot="{ field, meta }">
                        <select v-bind="field" @change="orderStore.getDistricts($event)"
                                :class="{'uk-select uk-border-rounded': true, 'uk-form-danger': meta.touched && !meta.valid}">
                            <option value="">-</option>
                            <option v-for="(city, index) in orderStore.cities" :key="index" :value="city.id"
                                    v-text="city.title"/>
                        </select>
                    </Field>
                </div>
                <ErrorMessage name="cityId" as="div" class="uk-text-danger uk-text-small uk-margin-small-top"/>
            </div>
            <div>
                <label class="uk-form-label">{{ $t('district') }}<span
                    class="red-dot uk-text-danger uk-margin-small-left"></span></label>
                <div class="uk-form-controls">
                    <Field name="districtId" v-model="address.districtId" v-slot="{ field, meta }">
                        <select v-bind="field" @change="orderStore.getWards($event)"
                                :class="{'uk-select uk-border-rounded': true, 'uk-form-danger': meta.touched && !meta.valid}">
                            <option value="">-</option>
                            <option v-for="(district, index) in orderStore.districts" :key="index"
                                    :value="district.id" v-text="district.title"/>
                        </select>
                    </Field>
                </div>
                <ErrorMessage name="districtId" as="div"
                              class="uk-text-danger uk-text-small uk-margin-small-top"/>
            </div>
            <div>
                <label class="uk-form-label">{{ $t('ward') }}<span
                    class="red-dot uk-text-danger uk-margin-small-left"></span></label>
                <div class="uk-form-controls">
                    <Field name="wardId" v-model="address.wardId" v-slot="{ field, meta }">
                        <select v-bind="field"
                                :class="{'uk-select uk-border-rounded': true, 'uk-form-danger': meta.touched && !meta.valid}">
                            <option value="">-</option>
                            <option v-for="(ward, index) in orderStore.wards" :key="index" :value="ward.id"
                                    v-text="ward.title"/>
                        </select>
                    </Field>
                </div>
                <ErrorMessage name="wardId" as="div" class="uk-text-danger uk-text-small uk-margin-small-top"/>
            </div>
        </div>
        <div class="uk-margin">
            <label class="uk-form-label">{{ $t('detail_address') }}<span
                class="red-dot uk-text-danger uk-margin-small-left"></span></label>
            <div class="uk-form-controls">
                <Field name="detail" v-model="address.detail" v-slot="{ field, meta }">
                    <input v-bind="field" type="text"
                           :class="{'uk-input uk-border-rounded': true, 'uk-form-danger': meta.touched && !meta.valid}"/>
                </Field>
            </div>
            <ErrorMessage name="detail" as="div" class="uk-text-danger uk-text-small uk-margin-small-top"/>
        </div>
        <div>
            <label class="uk-form-label">{{ $t('note') }}</label>
            <div class="uk-form-controls">
                <Field name="note" v-model="note" v-slot="{ field, meta }">
                        <textarea v-bind="field" rows="3"
                                  :class="{'uk-textarea uk-border-rounded': true, 'uk-form-danger': meta.touched && !meta.valid}"></textarea>
                </Field>
            </div>
            <ErrorMessage name="note" as="div" class="uk-text-danger uk-text-small uk-margin-small-top"/>
        </div>
    </Form>
</template>
