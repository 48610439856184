<script setup>
import ProductInfo from "@/components/ProductInfo.vue";
import ProductSlider from "@/components/ProductSlider.vue";
import {ref} from "vue";

const props = defineProps({
    product: Object,
    images: Array,
    video: Object,
});

const selectedModel = ref(null);

const selectModel = (model) => {
    if (model) {
        selectedModel.value = model;
    }
    else {
        selectedModel.value = null;
    }
}
</script>

<template>
    <div uk-grid class="uk-margin">
        <div class="uk-width-1-3@s">
            <product-slider :product="product" :images="images" :video="video"
                :selectedModel="selectedModel"></product-slider>
        </div>
        <div class="uk-width-2-3@s">
            <product-info :product="product" :images="images" :video="video"
                :selectedModel="selectedModel"
                @select="selectModel"></product-info>
        </div>
    </div>
</template>
