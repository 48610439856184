import axios from 'axios';
import {defineStore} from 'pinia';
import {ref, computed} from 'vue';
import {dialog} from '@/helpers/dialogHelper';
import Swal from 'sweetalert2';
import {useI18n} from 'vue-i18n';

const URL_GET_CONTENT = '/cart/get-content';
const URL_ADD = '/cart/add';
const URL_REMOVE = '/cart/remove';
const URL_UPDATE = '/cart/update';
const URL_CLEAR = '/cart/clear';

export const useCartStore = defineStore('cart', () => {
    const {t, n} = useI18n();

    // state - BEGIN
    const items = ref([]);
    const loading = ref(false);
    // state - END

    // getters - BEGIN
    const total = computed(() => {
        return items.value.reduce((total, item) => {
            if (item.options.selected) {
                return total + (item.price * item.qty)
            }
            else {
                return total;
            }
        }, 0);
    });

    const selected = computed(() => {
        var selected = true;
        items.value.forEach((item) => {
            selected &&= item.options.selected;
        });
        return selected;
    });

    const empty = computed(() => items.value.length == 0);
    const notEmpty = computed(() => items.value.length > 0);
    const itemCount = computed(() => items.value.length);
    const selectedItemCount = computed(() => items.value.filter((item) => item.options.selected).length);
    const selectedItems = computed(() => items.value.filter((item) => item.options.selected));
    // getters - END

    // actions - BEGIN
    function insert(item) {
        const index = findRowIndex(item);
        if (index > -1) {
            items.value.splice(index, 1);
        }

        items.value = [...items.value, item];
    }

    function insertAll(list) {
        list.forEach((item) => {
            // items.value.push(list[key]);

            items.value = [...items.value, item];
        });
    }

    const add = (product, model, qty) => {
        loading.value = true;

        axios.get(URL_ADD, {
            params: {
                'product_id': product.id ?? 0,
                'model_id': model?.id ?? 0,
                'qty': qty,
            }
        })
        .then(async (response) => {
            if (response.status == 200) {
                const data = response.data;

                if (data.message && data.item) {
                    if (data.message.code == 0) {
                        insert(data.item);

                        const result = await Swal.fire({
                            text: t('product_added'),
                            icon: 'success',
                            showConfirmButton: true,
                            confirmButtonColor: "#d81557",
                            confirmButtonText: t('view_cart'),
                            showCancelButton: true,
                            cancelButtonText: t('continue_shopping'),
                        });

                        if (result.isConfirmed) {
                            window.location.href = '/cart';
                        }
                    }
                    else {
                        dialog.error(data.message.content);
                    }
                }
                else {
                    dialog.error('Unexpected response data!');
                }
            }
            else {
                dialog.error('Unexpected response status!');
            }
        })
        .catch((error) => {
            dialog.error(error.message);
        })
        .finally(() => {
            loading.value = false;
        });
    }

    function getContent() {
        loading.value = true;

        axios.get(URL_GET_CONTENT, {})
        .then((response) => {
            if (response.status == 200) {
                const data = response.data;

                if (data.list) {
                    insertAll(data.list);
                }
                else {
                    dialog.error('Unexpected response data!');
                }
            }
            else {
                dialog.error('Unexpected response status!');
            }
        })
        .catch((error) => {
            dialog.error(error.message);
        })
        .finally(() => {
            loading.value = false;
        });
    }

    function getIndex(item) {
        return items.value.indexOf(item);
    }

    function findIndex(item) {
        return items.value.findIndex((el) => el.id == item.id);
    }

    function findRowIndex(item) {
        return items.value.findIndex((el) => el.rowId == item.rowId);
    }

    function purchase(product, model, qty) {
    }

    function increaseQty(item) {
        item.qty++;
        update(item);
    }

    function decreaseQty(item) {
        if (item.qty > 1) {
            item.qty--;
            update(item);
        }
    }

    function replaceRow(oldItem, newItem) {
        const index = findRowIndex(oldItem);
        if (index > -1) {
            items.value[index] = newItem;
        }
    }

    function remove(item) {
        loading.value = true;

        axios.post(URL_REMOVE, {
            row_id: item.rowId
        })
        .then((response) => {
            if (response.status == 200) {
                const data = response.data;
                if (data.message.code == 0) {
                    const index = findRowIndex(item);
                    if (index > -1) {
                        items.value.splice(index, 1);
                    }
                }
                else {
                    dialog.error(data.message.content);
                }
            }
            else {
                dialog.error('Unexpected response status!');
            }
        })
        .catch((error) => {
            dialog.error(error.message);
        })
        .finally(() => {
            loading.value = false;
        });
    }

    function update(item) {
        loading.value = true;

        axios.post(URL_UPDATE, {
            row_id: item.rowId,
            item: item,
        })
        .then((response) => {
            if (response.status == 200) {
                const data = response.data;
                if (data.message && data.item) {
                    if (data.message.code == 0) {
                        replaceRow(item, data.item);
                    }
                    else {
                        dialog.error(data.message.content);
                    }
                }
                else {
                    dialog.error('Unexpected response data!');
                }
            }
            else {
                dialog.error('Unexpected response status!');
            }
        })
        .catch((error) => {
            dialog.error(error.message);
        })
        .finally(() => {
            loading.value = false;
        });
    }

    function toggleAll() {
        var checked = document.getElementById('cart-toggle').checked;

        items.value.forEach((item) => {
            item.options.selected = checked;
        });
    }

    function clearSelected() {
        items.value = items.value.filter((item) => !item.options.selected);
    }

    function clear() {
        loading.value = true;

        axios.post(URL_CLEAR, {})
        .then((response) => {
            if (response.status == 200) {
                const data = response.data;
                if (data.message.code == 0) {
                    items.value.splice(0, items.value.length);
                }
                else {
                    dialog.error(data.message.content);
                }
            }
            else {
                dialog.error('Unexpected response status!');
            }
        })
        .catch((error) => {
            dialog.error(error.message);
        })
        .finally(() => {
            loading.value = false;
        });
    }
    // actions - END

    return {
        // state
        items,
        loading,

        // getters
        total,
        selected,
        empty,
        notEmpty,
        itemCount,
        selectedItemCount,
        selectedItems,

        // actions
        add,
        remove,
        update,
        getContent,
        toggleAll,
        increaseQty,
        decreaseQty,
        clear,
        clearSelected,
    };
});
