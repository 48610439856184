<script setup>
import {useOrderStore} from "@/stores/orderStore";

const orderStore = useOrderStore();

const props = defineProps({
    shippingPolicy: {type: String, deffault: ''}
})
</script>

<template>
    <div class="uk-grid-small" uk-grid>
        <div v-text="$t('product_subtotal')" class="uk-width-expand"></div>
        <div v-text="$n(orderStore.productSubtotal, 'currency')"></div>
    </div>
    <div class="uk-grid-small" uk-grid>
        <div v-text="$t('shipping_subtotal')" class="uk-width-expand"></div>
        <template v-if="!isNaN(orderStore.shippingSubtotal)">
            <div v-text="$n(orderStore.shippingSubtotal, 'currency')"></div>
        </template>
        <template v-else>
            <div>-</div>
        </template>
    </div>
    <hr class="uk-margin-small" />
    <div class="uk-grid-small" uk-grid>
        <div v-text="$t('payment_total')" class="uk-width-expand uk-text-bold"></div>
        <div v-text="$n(orderStore.paymentTotal, 'currency')" class="uk-text-bold"></div>
    </div>
    <div class="uk-margin-small-top">
        <div v-if="shippingPolicy" class="uk-padding-small uk-text-small border-primary border-dashed"
            v-html="shippingPolicy" />
    </div>
</template>
