import Swal from 'sweetalert2';

export const dialog = {
    success: (message, options = {}) => Swal.fire({
        text: message,
        icon: 'success',
        confirmButtonColor: "#d81557",
    }),
    error: (message, options = {}) => Swal.fire({
        text: message,
        icon: 'error',
        confirmButtonColor: "#d81557",
    }),
    info: (message, options = {}) => Swal.fire({
        text: message,
        icon: 'info',
        confirmButtonColor: "#d81557",
    }),
    warning: (message, options = {}) => Swal.fire({
        text: message,
        icon: 'warning',
        confirmButtonColor: "#d81557",
    }),
};
