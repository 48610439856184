import axios from 'axios';
import {defineStore} from 'pinia';
import {ref, computed} from 'vue';
import {dialog} from '@/helpers/dialogHelper';
import {useI18n} from 'vue-i18n';
import {useCartStore} from "@/stores/cartStore";

const URL_ORDER_PLACE = '/order/place';
const URL_CITY_LIST = '/area/city';
const URL_DISTRICT_LIST = '/area/district';
const URL_WARD_LIST = '/area/ward';

const PM_COD = 1;
const PM_VIETQR = 2;

const DM_HOME_DELIVERY = 1;
const DM_PICK_AT_STORE = 2;

export const useOrderStore = defineStore('order', () => {
    const {t, n} = useI18n();
    const cartStore = useCartStore();

    // state - BEGIN
    const loading = ref(false);
    const completed = ref(false);
    const order = ref(null);
    const address = ref({
            name: '',
            tel: '',
            cityId: '',
            districtId: '',
            wardId: '',
            detail: '',
        });

    const note = ref('');
    const cities = ref([]);
    const districts = ref([]);
    const wards = ref([]);

    const paymentMethod = ref(PM_COD);
    const deliveryMethod = ref(DM_HOME_DELIVERY);
    // state - END

    // getters - BEGIN
    const productSubtotal = computed(() => cartStore.total);
    const shippingSubtotal = computed(() => productSubtotal.value >= 500000 ? 0 : 30000);
    const paymentTotal = computed(() => productSubtotal.value + shippingSubtotal.value);
    // getters - END

    // actions - BEGIN
    function getCities() {
        loading.value = true;

        axios.get(URL_CITY_LIST, {})
        .then((response) => {
            if (response.status == 200) {
                if (response.data) {
                    cities.value = response.data;
                }
                else {
                    dialog.error('Unexpected response data!');
                }
            }
            else {
                dialog.error('Unexpected response status!');
            }
        })
        .catch((error) => {
            dialog.error(error.message);
        })
        .finally(() => {
            loading.value = false;
        });
    }

    function getDistricts(e) {
        districts.value = [];
        address.value.districtId = '';

        wards.value = [];
        address.value.wardId = '';

        const cityId = e.target.value;
        if (cityId == '') {
            return;
        };

        loading.value = true;

        axios.get(URL_DISTRICT_LIST, {
            params: {
                parent_id: cityId,
            }
        })
        .then((response) => {
            if (response.status == 200) {
                if (response.data) {
                    districts.value = response.data;
                }
                else {
                    dialog.error('Unexpected response data!');
                }
            }
            else {
                dialog.error('Unexpected response status!');
            }
        })
        .catch((error) => {
            dialog.error(error.message);
        })
        .finally(() => {
            loading.value = false;
        });
    }

    function getWards(e) {
        wards.value = [];
        address.value.wardId = '';

        const districtId = e.target.value;
        if (districtId == '') {
            return;
        }

        loading.value = true;

        axios.get(URL_WARD_LIST, {
            params: {
                parent_id: districtId,
            }
        })
        .then((response) => {
            if (response.status == 200) {
                if (response.data) {
                    wards.value = response.data;
                }
                else {
                    dialog.error('Unexpected response data!');
                }
            }
            else {
                dialog.error('Unexpected response status!');
            }
        })
        .catch((error) => {
            dialog.error(error.message);
        })
        .finally(() => {
            loading.value = false;
        });
    }

    function place() {
        loading.value = true;

        axios.post(URL_ORDER_PLACE, {
            'address': address.value,
            'note': note.value,
            'payment_method': paymentMethod.value,
            'delivery_method': deliveryMethod.value,
        })
        .then((response) => {
            if (response.status == 200) {
                const data = response.data;

                if (data.message) {
                    if ((data.message.code == 0) && (data.item)) {
                        console.log(data.item);
                        order.value = data.item;
                        completed.value = true;
                        cartStore.clearSelected();
                    }
                    else {
                        dialog.error(data.message.content);
                    }
                }
                else {
                    dialog.error('Unexpected response data!');
                }
            }
            else {
                dialog.error('Unexpected response status!');
            }
        })
        .catch((error) => {
            dialog.error(error.message);
        })
        .finally(() => {
            loading.value = false;
        });
    }
    // actions - END

    return {
        // state
        address,
        note,
        loading,
        completed,
        order,
        cities,
        districts,
        wards,

        // getters
        productSubtotal,
        shippingSubtotal,
        paymentTotal,

        // actions
        getCities,
        getDistricts,
        getWards,
        place
    };
});
