import axios from "axios";
window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

import.meta.glob([
    "../images/**",
    "../fonts/**",
]);

import $ from "jquery";
window.$ = window.jQuery = $;

import "busy-load/dist/app.js";
import "busy-load/dist/app.css";

import UIkit from "uikit";
window.UIkit = UIkit

import Icons from "uikit/dist/js/uikit-icons";
window.UIkit.use(Icons);

import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/light.css";
window.flatpickr = flatpickr;

import { Vietnamese } from "flatpickr/dist/l10n/vn.js"
flatpickr.localize(flatpickr.l10ns.vn);
